import React, { Suspense, lazy } from "react";
import { createRoot } from "react-dom/client"; // Import createRoot from react-dom/client
import "./index.css";
import * as serviceWorker from "./serviceWorker";

const Rootapp = lazy(() => import("./App"));

const container = document.getElementById("root");
const root = createRoot(container); // Create the root using createRoot

root.render(
  <React.StrictMode>
    <Suspense fallback={<div></div>}>
      <Rootapp />
    </Suspense>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
